
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditProductAttributeModal from "@/components/modals/referencials/product-attributes/EditProductAttributeModal.vue";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "referencials-products",
    props: {
        widgetClasses: String,
    },
    components: {
        EditProductAttributeModal,
    },
    setup() {
        const { t, te } = useI18n();
        let loading = ref(false);
        let activeProductAttribute = ref(null);
        const productAttributes = ref([]);
        const queryParamsDefault = {
            name          : "",
            options_array : [],
        };
        const queryParams = ref({});
        Object.assign(queryParams.value, queryParamsDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const queryParamsLabels = {
            name          : translate("productAttributeNameLabel"),
            options_array : translate("productAttributeOptionsLabel"),
        };
        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        const goPage = (page: number) => {
            pagination.value.page = page;

            getProductAttributes();
        };

        const setOffset = (event) => {
            pagination.value.offset = parseInt(event.target.value);

            getProductAttributes();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getProductAttributes();
        };

        const create = () => {
            activeProductAttribute.value = null;
            const modal = new Modal(document.getElementById('kt_modal_edit_product_attribute'));
            modal.show();
        };

        const edit = (supplier) => {
            activeProductAttribute.value = supplier;
            const modal = new Modal(document.getElementById('kt_modal_edit_product_attribute'));
            modal.show();
        };

        const confirmRemove = (supplier_id: number) => {

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("productConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(supplier_id);
                }
            });
        }

        const remove = (product_attribute_id: number) => {

            axios.delete(`${ApiRoutes.productAttributes.delete}/${product_attribute_id}`)
                .then((response: AxiosResponse) => {

                    if (response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            refresh();
                        });
                    } else {

                        Swal.fire({
                            text: response.data.message,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const removeQueryParam = (name: string): void => {
            if (queryParams.value && typeof queryParams.value[name] !== 'undefined') {
                queryParams.value[name] = queryParamsDefault[name];

                refresh();
            }
        };

        const getProductAttributes = async () => {
            loading.value = true;

            const params = {};
            Object.assign(params, queryParams.value, pagination.value);

            await axios.get(ApiRoutes.productAttributes.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    productAttributes.value = response.data.rows;
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const searchProducts = (params) => {
            Object.assign(queryParams.value, params);

            refresh();
        };

        onMounted(() => {
            getProductAttributes();

            setCurrentPageBreadcrumbs(translate("productManagementHeading"), [
                translate("masterDataHeading"),
                translate("referencialsHeading"),
                translate("stockManagementHeading")
            ]);
        });

        return {
            translate,
            productAttributes,
            queryParams,
            queryParamsDefault,
            queryParamsLabels,
            removeQueryParam,
            searchProducts,
            pagination,
            activeProductAttribute,
            loading,
            refresh,
            goPage,
            create,
            edit,
            confirmRemove,
            setOffset,
        };
    },
});
