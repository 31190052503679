
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";
import { hideModal } from "@/core/helpers/dom";
import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";

export default defineComponent({
    name: "referencials-product-Attribute-edit-modal",
    emit: ["refreshData"],
    props: {
        productAttribute: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        FieldError,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editProductAttributeModalRef = ref<null | HTMLElement>(null);

        let errors: any = ref();
        let loading = ref(false);
        let isUpdate = false as boolean;
        let modal = ref();
        const productAttributes = ref([]);

        const productAttributeDefault = {
            id           : null,
            name         : "",
            options_array: [],
        };
        const productAttributeData = ref<any>({});
        Object.assign(productAttributeData.value, productAttributeDefault);

        const validationSchema = Yup.object().shape({
        });

        const submit = (values, actions) => {
            errors.value = null;

            disableSubmitButton(submitButtonRef);

            if (isUpdate && props.productAttribute.id) productAttributeData.value.id = props.productAttribute.id;

            axios.post(ApiRoutes.productAttributes.updateOrCreate, productAttributeData.value)
                .then((response: AxiosResponse) => {

                    enableSubmitButton(submitButtonRef);

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            hideModal(editProductAttributeModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    enableSubmitButton(submitButtonRef);

                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                });
        };

        onMounted(() => {

            modal.value = document.getElementById('kt_modal_edit_product_attribute');

            if (modal.value) modal.value.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(productAttributeData.value, productAttributeDefault);
                productAttributeData.value.options_array = [];
            });

            if (modal.value) modal.value.addEventListener('shown.bs.modal', async function() {
                isUpdate = false;
                errors.value = null;

                if (props.productAttribute) {
                    isUpdate = true;

                    // Fill productAttribute fields
                    Object.keys(props.productAttribute).forEach((key) => {
                        if (productAttributeDefault.hasOwnProperty(key)) productAttributeData.value[key] = props.productAttribute[key];
                    });
                }
            });
        });

        return {
            translate,

            submitButtonRef,
            editProductAttributeModalRef,

            validationSchema,

            errors,
            resetField,
            checkField,

            modal,
            loading,
            productAttributes,
            productAttributeData,

            submit,
        };
    },
});
